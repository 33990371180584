<template>
  <div>
    <CCard>
      <CCardBody>
        <div class="row">
          <div class="col-md-5">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-md-5 ml-auto">
            <div class="row">
              <div class="col">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <button class="btn btn-sm btn-primary" @click="addUser()">
                  Tambah User
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
          <CDataTable
            class="table-striped"
            :items="computedItems"
            :fields="fields"
            style="max-height: 300px; overflow: auto;"
          >
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  @click="edit(item)"
                  class="mr-2"
                  color="warning"
                  square
                  size="sm"
                >
                  Edit
                </CButton>
                <CButton
                  @click="changePassword(item)"
                  class="mr-2"
                  color="info"
                  square
                  size="sm"
                >
                  Change Password
                </CButton>
                <CButton
                  v-if="item.id != user.id"
                  @click="hapus(item)"
                  color="danger"
                  square
                  size="sm"
                >
                  Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <pagination
            v-if="total !== items.length"
            v-model="page"
            :records="total"
            :per-page="50"
            @paginate="pagination"
          />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>
    <CModal
      size="lg"
      :title="isUpdate ? 'Edit User' : 'Tambah User'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col">
          <CInput
            v-model="form.full_name"
            label="Nama Lengkap"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.username"
            label="username"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.email"
            label="Email"
            type="email"
            placeholder="test@email.com"
          />
          <CInput
            v-if="!isUpdate"
            v-model="form.password"
            label="Password"
            type="password"
            placeholder="*******"
          />
          <CInput
            v-if="!isUpdate"
            v-model="form.password_confirmation"
            label="Konfirmasi Password"
            type="password"
            placeholder="ketik disini"
          />
        </div>
        <div class="col">
          <CInput
            v-model="form.position"
            label="Jabatan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.nip"
            label="NIP"
            placeholder="ketik disini"
          />
          <label for="Role">Role / PD</label>
          <v-select
            class="mb-3"
            v-model="form.role"
            placeholder="Pilih Role"
            :options="computedRole"
          ></v-select>
          <label for="Role">Jenis User</label>
          <v-select
            class="mb-3"
            v-model="form.type"
            placeholder="Pilih Role"
            :options="computedVerificator"
          ></v-select>
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah User
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update User
          </button>
        </div>
      </template>
    </CModal>
    <CModal
      size="lg"
      title="Change Password"
      centered
      color="primary"
      :show.sync="modalPassword"
      :closeOnBackdrop="false"
    >
      <div class="row">
        <div class="col-12">
          <CInput
            v-model="form.full_name"
            label="Nama User"
            placeholder="nama"
            disabled
          />
        </div>
        <div class="col-12">
          <CInput
            v-model="form.password"
            label="Password"
            type="password"
            placeholder="ketik disini"
            :is-valid="isPasswordValid"
            @update:value="inputPassword()"
            :lazy="false"
          />
        </div>
        <div class="col-12">
          <CInput
            v-model="form.password_confirmation"
            label="Konfirmasi Password"
            type="password"
            placeholder="ketik disini"
          />
        </div>
      </div>
      <template slot="footer">
        <div>
          <button @click="closeModalPassword" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="updatePassword" class="btn btn-primary">
            Update Password
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as data from "../../model/user"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      createModal: false,
      fields: data.fields,
      isUpdate: false,
      items: [],
      roles: [],
      regional_apparatuses: [],
      user: JSON.parse(localStorage.getItem("user")),
      page: 1,
      total: 0,
      form: {},
      params: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      isSearching: false,
      searchOn: "",
      modalPassword: false,
      isPasswordValid: null, //null/boolean
      invalidPassword: "",
      verificators: [
        { value: 0, label: "MASYARAKAT" },
        { value: 1, label: "SUPER ADMIN" },
        { value: 2, label: "PETUGAS" },
        { value: 3, label: "ADMIN" },
      ],
    }
  },
  methods: {
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "") {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit() {
      var loading = this.$loading.show()
      this.form.role_id = this.form.role ? this.form.role.value : null
      this.form.type = this.form.type ? this.form.type.value : null

      // insert ke database
      this.$store
        .dispatch("user/addUser", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan user")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update() {
      var loading = this.$loading.show()
      this.form.role_id = this.form.role ? this.form.role.value : null
      this.form.type = this.form.type ? this.form.type.value : null

      this.$store
        .dispatch("user/updateUser", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data user")
          loading.hide()
          this.createModal = false
          this.form = {}
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("user/deleteUser", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data user")
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("user/getUser", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          console.log(this.item)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()

          this.getDataRole()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataRole() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("role/get", params)
        .then((resp) => {
          this.roles = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    addUser() {
      this.isUpdate = false
      this.form = {}
      this.createModal = true
    },
    changePassword(item) {
      this.modalPassword = true
      this.form.id = item.id
      this.form.full_name = item.full_name
      this.form.firebase_id = item.firebase_id
    },
    inputPassword() {
      this.invalidPassword = ""
      this.isPasswordValid = null
    },
    validatePassword(item) {
      if (item.password.length < 6) {
        this.invalidPassword = "Password kurang dari 6 karakter!!"
        this.isPasswordValid = false
        return false
      } else if (item.password != item.password_confirmation) {
        this.invalidPassword = "Konfirmasi password tidak sama!!"
        this.isPasswordValid = false
        return false
      } else {
        this.invalidPassword = ""
        this.isPasswordValid = null
        return true
      }
    },
    updatePassword() {
      if (this.validatePassword(this.form)) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("user/changePassword", {
            id: this.form.id,
            password: this.form.password,
          })
          .then(() => {
            this.$toast.success("Berhasil merubah password user")
            loading.hide()
            this.modalPassword = false
            this.form = {}
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      } else {
        this.$toast.error(this.invalidPassword)
      }
    },
    closeModalPassword() {
      this.form = {}
      this.invalidPassword = ""
      this.isPasswordValid = null
      this.modalPassword = false
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByUserId(userId) {
      firebase
        .auth()
        .getUser(userId)
        .then((userRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteUser(userRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log("Akun berhasil dihapus berdasarkan ID pengguna:", userId)
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
  },
  computed: {
    computedItems() {
      return this.items.map((item, index) => {
        return {
          ...item,
          role: item.roles ? item.roles.name : "Tidak ada",
          pd: item.regional_apparatus ? item.regional_apparatus.name : "-",
          type: this.getNameVerificator(item.type),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedRole() {
      if (this.form.role_id) {
        let role = {
          value: this.form.role_id,
          label: this.roles.find((element) => {
            return element.id == this.form.role_id
          }).name,
        }
        this.form.role = role
      }
      return this.roles.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
    computedVerificator() {
      console.log(this.form.verificator)
      if (this.form.verificator || this.form.verificator === 0) {
        let doc = this.verificators.find((element) => {
          return element.value == this.form.verificator
        })
        console.log(doc)
        if (doc) {
          let verificator = {
            value: doc.value,
            label: doc.label,
          }
          this.form.verificators = verificator
        }
      }
      return this.verificators.map((item) => {
        return {
          value: item.value,
          label: item.label,
        }
      })
    },
  },
  mounted() {
    console.log(firebase, "firebase")
    this.getData()

    let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    let wrapper_2 = document.querySelector('#wrapper2');
    wrapper_1.onscroll = function() {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
      
    wrapper_2.onscroll = function() {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>